@import '/styles/variables';
@import '/styles/media';
@import '/styles/utils';

.content {
  position: relative;
  display: flex;
  padding: 80px 0;
  width: 100%;

  @include media-breakpoint-down(lg) {
    flex-direction: column !important;
  }
}

.header {
  width: 100%;
  display: grid;
  gap: 8px;
}

.subtitle {
  color: color(neutral);
  text-transform: uppercase;
  letter-spacing: 0.1em;

  @include media-breakpoint-down(lg) {
    text-align: center;
  }
}

@keyframes blink {
  40% {
    opacity: 1;
  }
  42% {
    opacity: 0.8;
  }
  43% {
    opacity: 1;
  }
  45% {
    opacity: 0.2;
  }
  46% {
    opacity: 1;
  }
}

.title {
  font-size: 56px;
  line-height: 60px;
  color: transparent;
  will-change: opacity;
  animation: blink 6s infinite alternate;

  &.turn-off-animation {
    animation: unset;
  }

  @include media-breakpoint-down(lg) {
    text-align: center;
  }

  @include media-breakpoint-down(sm) {
    line-height: 40px;
    font-size: 32px;
  }
}

.right {
  justify-content: center;
  position: relative;
  display: flex;
  flex: 0 0 50%;
  max-width: 50%;
  align-items: center;

  @include media-breakpoint-down(lg) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 0 0 50%;
  max-width: 50%;
  height: 100%;
  padding-right: 60px;

  @include media-breakpoint-down(lg) {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: 40px;
  }

  & > *:not(:last-child) {
    margin-bottom: 24px;
  }
}

.description {
  @include media-breakpoint-down(lg) {
    text-align: center;
  }
}

.link {
  text-decoration: none;
  text-transform: uppercase;
}

.buttons-wrapper {
  width: 100%;
  justify-content: start;
  display: grid;
  gap: 16px;
  grid-row: 16px;
  grid-auto-flow: column;

  @include media-breakpoint-down(lg) {
    justify-content: center;
  }

  @include media-breakpoint-down(md) {
    justify-content: stretch;
    grid-auto-flow: row;
  }
}

.image {
  border-radius: 12px;
  width: 100%;
  object-fit: cover;
  aspect-ratio: 3 / 2;
}

.reverse {
  @include media-breakpoint-up(lg) {
    flex-direction: row-reverse;

    .left {
      padding-left: 60px;
    }
  }
}

.sign {
  display: grid;
  gap: 16px;
  position: absolute;
  left: calc(50% - 40px);
  z-index: 1;
  color: white;
  top: 24%;

  @include media-breakpoint-down(lg) {
    display: none;
  }

  & div {
    height: 6px;
    width: 80px;
    background-color: currentColor;
  }
}

.tags {
  display: flex;

  @include media-breakpoint-down(md) {
    justify-content: center;
  }

  & .tag {
    padding: 4px 12px;
    border-radius: 8px;
    color: map-get($colors, g-white);
    border: 1px solid map-get($colors, g-white);
    margin-bottom: 10px;

    @each $key, $value in $colors {
      &.#{'' + $key} {
        box-shadow: 0px 0px 35px transparentize($value, 0.9),
          0px 0px 25px transparentize($value, 0.75), 0px 0px 50px transparentize($value, 0.85),
          inset 0px 0px 25px transparentize($value, 0.75),
          inset 0px 0px 50px transparentize($value, 0.85);
      }
    }
  }
}

.icon {
  margin-bottom: 24px;
}
