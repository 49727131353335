@use '/styles/variables' as *;

.description {
  a {
    color: color(green);
    &:hover {
      @extend %text-shadow-light-green;
      transition: all 0.05s ease-in-out;
      svg {
        @extend %filter-shadow-green;

        transition: all 0.05s ease-in-out;
      }
    }
  }

  &.standart {
    color: map-get($colors, g-white);
    letter-spacing: 0.02em;
    font-size: 0.9375rem;
    line-height: 1.5rem;
    font-weight: 400;
  }

  ul {
    all: unset;
    list-style-position: inside;
  }
}
