@use '/styles/variables' as *;

.title {
  margin-bottom: 0.5em;
}

.text {
  margin-bottom: 24px;
  display: block;
}

.bottom {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.form {
  display: grid;
  gap: 20px;
}
