@import '/styles/variables';

.title {
  margin-bottom: 12px;
}

.wrapper {
  text-align: center;
  padding: 16px 0;
}

.actions {
  margin-top: 24px;
  display: flex;
  justify-content: center;
}
