@use '/styles/variables' as *;
@use '/styles/media' as *;

.wrapper {
  flex-direction: column;
  overflow: hidden;
  display: flex;
  width: 100%;
}

.gallery {
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}

.slides {
  display: flex;
  transform: translate(calc(var(--i, 0) * -100% + var(--tx, 0px)));
  will-change: transform;

  &-smooth {
    transition: 0.3s transform;
  }
}

.slide {
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
  max-width: 100%;
  flex: 0 0 100%;
  display: flex;

  &::before {
    content: '';
    display: block;
    // 1:1	100%
    // 16:9	56.25%
    // 4:3	75%
    // 3:2	66.66%
    // 8:5	62.5%
    padding-top: 56.25%;
  }

  & img,
  & iframe {
    width: 100%;
    height: 100%;
    position: absolute;
  }

  & img {
    object-fit: cover;
    transition: 0.3s;
  }

  &-clickable {
    cursor: pointer;

    & img:hover {
      transform: scale(1.1);
    }
  }
}

.button {
  position: absolute;
  outline: none;
  background-color: rgba(0, 0, 0, 0.1);
  border: none;
  color: white;
  cursor: pointer;
  padding: 20px 16px;
  display: flex;
  transition: 0.3s color, 0.3s background-color;

  &:hover {
    color: $g-primary;
    background: rgba(0, 0, 0, 0.6);
  }

  &-next {
    right: 0;
    border-radius: 4px 0 0 4px;
  }

  &-prev {
    left: 0;
    border-radius: 0 4px 4px 0;
  }
}

.dots {
  display: grid;
  justify-content: center;
  padding: 27px 0;
  grid-auto-flow: column;
  gap: 24px;

  & .dot {
    height: 10px;
    width: 10px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 50%;
    cursor: pointer;
    will-change: filter;

    &-active {
      background-color: transparent;
      border: 2px solid lighten($g-primary, 20%);

      @extend %filter-shadow-primary;
    }
  }
}

@each $key, $value in $colors {
  .#{'' + $key} {
    .dot-active {
      border: 2px solid lighten($value, 25%);

      @extend %filter-shadow-#{'' + $key};
    }
  }
}
